export default async function updateEcommerceData ($axios, store, queryToken) {
    const [
        cartResponse,
        customerResponse,
    ] = await Promise.all([
        $axios.get(`${process.env.API_ECOMMERCE_ORIGIN}/cart/${queryToken}`),
        $axios.get(`${process.env.API_ECOMMERCE_ORIGIN}/customer/${queryToken}`),
    ])

    const { totalAmount, deliveryCost, products } = cartResponse.data
    store.commit('ecommerce/setCart', {
        totalAmount: totalAmount / 100,
        deliveryCost: typeof deliveryCost === 'number' ? deliveryCost / 100 : null,
        products,
    })
    const { productType, firstName, lastName, email, phone } = customerResponse.data
    store.commit('ecommerce/setInitialProductType', productType)
    store.commit('ecommerce/setCustomerData', {
        firstName,
        lastName,
        email,
        phone,
    })
}
