import { EventSourcePolyfill } from 'event-source-polyfill'

export default {
    async created () {
        const orderToken = this.$route.query.token
        const response = await this.$axios.get('/mercure-discovery/' + orderToken)

        const mercureLink = response.data.mercureLink
        const jwtToken = response.data.jwtToken

        const hub = new URL(mercureLink)
        hub.searchParams.append('topic', orderToken)

        const eventSource = new EventSourcePolyfill(hub, {
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        })

        eventSource.onmessage = event => {
            this.$router.push(`/ecommerce/cancelled/?token=${this.$route.query.token}`)
        }
    },
}
