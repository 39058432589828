import Cookies from 'js-cookie'
import { COMFINO_SID_KEY } from '~/utils/consts'
import removeSession from '~/utils/session-remover'

export default function ({ $axios, route, params }, inject) {
    const customerAccountAxios = $axios.create({
        baseURL: process.env.API_CUSTOMER_ACCOUNT_ORIGIN,
    })

    customerAccountAxios.onRequest(config => {
        config.headers.common[COMFINO_SID_KEY] = Cookies.get(COMFINO_SID_KEY)
    })

    customerAccountAxios.onError(error => {
        const code = parseInt(error.response && error.response.status)

        if (code === 401) {
            removeSession(route.query.token)
            location.reload()
        }
    })

    inject('accountAxios', customerAccountAxios)
}
