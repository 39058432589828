<template>
    <v-dialog
        :value="value"
        max-width="800px"
        transition="dialog-bottom-transition"
        @input="$emit('input', false)"
    >
        <DialogCloseBtn @input="$emit('input', false)"></DialogCloseBtn>
        <div class="dialog-content">
            <div class="header-images-wrapper">
                <img
                    v-if="showHeaderImage"
                    :src="`/agreements/${isMobile ? 'logo-mobile.png' : 'logo-big.png'}`"
                    class="header-comfino-logo"
                    alt="comfino-logo"
                >
            </div>
            <slot :close="closeDialog"></slot>
            <v-btn
                v-if="showCloseButton"
                class="bottom-close-button rounded-lg"
                :block="isMobile"
                outlined
                width="115px"
                color="#006FB2"
                @click="$emit('input', false)"
            >
                zamknij
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
import DialogCloseBtn from '~/components/partials/dialog/ close-button/DialogCloseBtn.vue'

export default {
    components: { DialogCloseBtn },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        showHeaderImage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isMobile () {
            return this.$vuetify.breakpoint.width < 550
        },
    },
    methods: {
        closeDialog () {
            this.$emit('input', false)
        },
    },
}
</script>

<style lang="scss">
.ecommerce .v-dialog {
    box-shadow: none;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-inline: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    max-height: calc(100% - 80px);
    .dialog-close {
        position: absolute;
        z-index: 1;
        top: 20px;
        right: 15px;
        padding: 20px;
      &:before {
        background-color: #ebebeb;
        opacity: 1;
        box-shadow: 0 0 24px #00000026;
      }
    }
    .dialog-content {
      position: relative;
      overflow: auto;
      max-height: 100%;
      padding: 0 30px 0 30px;
      border-top: 40px solid #ebebeb;
      border-bottom: 40px solid #ebebeb;
      background: #ebebeb;
      -ms-overflow-style: none;
      scrollbar-width: none;
      .header-images-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        img {
          width: 100%;
          height: auto;
        }
      }
      .bottom-close-button {
        display: block;
        margin: auto;
        text-transform: lowercase;
      }
    }
}
.dialog-content::-webkit-scrollbar {
  display: none;
}
@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .ecommerce .v-dialog {
        margin-top: 0;
        border-radius: 13px;
        max-height: 80%;
    }
}
</style>
