export function hasRouteUTM (route) {
    return Object.keys(route.query).some(utmKey => utmKey.startsWith('utm_'))
}

export function prepareRouteWithUTM (route, from) {
    const routeWithUTM = { ...route }
    Object.keys(from.query).forEach(utmKey => {
        routeWithUTM.query[utmKey] = from.query[utmKey]
    })
    return routeWithUTM
}
