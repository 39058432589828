<template>
    <v-btn
        icon
        class="dialog-close"
        @click="$emit('input', false)"
    >
        <v-icon
            :size="iconSize"
            :style="{ color: iconColor }"
        >
            mdi-close-circle
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        iconSize: {
            type: [String, Number],
            default: 32,
        },
        iconColor: {
            type: String,
            default: '#006FB2',
        },
    },
}
</script>

<style lang="scss">
  .dialog-close {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 15px;
    padding: 20px;

    &:before {
      background-color: #ebebeb;
      opacity: 1;
      box-shadow: 0 0 24px #00000026;
    }
  }
</style>
