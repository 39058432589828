export default async function ({ store, route, redirect }) {
    const bc = new BroadcastChannel('wniosek')
    const { token } = route.query

    bc.onmessage = ({ data }) => {
        if (!data.includes(token)) return
        store.commit('setIsWindowActiveStatus', false)
        redirect(`/ecommerce/blank?token=${token}`)
    }

    function callback () {
        bc.postMessage(`Deactivate other tabs. Source token: ${token}`)
        if (store.state.isWindowActive === false) {
            store.commit('setIsWindowActiveStatus', true)
            location.reload()
        }
    }

    window.addEventListener('load', callback)
    window.addEventListener('focus', callback)
}
