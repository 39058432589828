<template>
    <Dialog
        v-model="showPrivacyPolicy"
        :show-close-button="true"
        :show-header-image="true"
    >
        <div v-dompurify-html="privacyPolicy"></div>
    </Dialog>
</template>

<script>
import Dialog from '~/components/partials/dialog/ecommerce/Dialog'
import privacyPolicy from '../privacy-policy'

export default {
    components: {
        Dialog,
    },
    mixins: [privacyPolicy],
    computed: {
        isMobile () {
            return this.$vuetify.breakpoint.width < 550
        },
    },
}
</script>
