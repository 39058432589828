export default async function utmTracker ({ $axios, route }) {
    const { token } = route.query

    const getUTMParams = (route) => {
        return Object.keys(route.query).reduce((params, paramKey) => {
            if (paramKey.startsWith('utm_') && route.query[paramKey]) {
                params[paramKey] = route.query[paramKey]
            }
            return params
        }, {})
    }

    const mapUTMParamsToArray = (utmObject) => {
        return Object.keys(utmObject).map(key => ({ key: key, value: utmObject[key] }))
    }

    const saveUTMToLocalStorage = (utmParams) => {
        localStorage.setItem('UTMParams', JSON.stringify(utmParams))
    }

    const getUTMFromLocalStorage = () => {
        const utmParams = localStorage.getItem('UTMParams')
        return utmParams ? JSON.parse(utmParams) : {}
    }
    const shouldSaveUTM = (utmParams) => {
        return Object.keys(utmParams).length > 0
    }

    const hasUtmChanged = (current, previous) => {
        return JSON.stringify(current) !== JSON.stringify(previous)
    }

    const currentUTMParams = getUTMParams(route)
    const previousUTMParams = getUTMFromLocalStorage()

    if (!shouldSaveUTM(currentUTMParams)) {
        saveUTMToLocalStorage({})
        return
    }

    if (!hasUtmChanged(currentUTMParams, previousUTMParams)) {
        return
    }

    saveUTMToLocalStorage(currentUTMParams)

    try {
        await $axios.patch(`/orders/${token}/utms`, mapUTMParamsToArray(currentUTMParams))
    } catch (error) {
        console.log(error)
    }
}
