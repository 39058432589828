import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    store: {
        name: null,
        id: null,
        logoUrl: null,
        cssUrl: null,
    },
    storeReturnUrl: null,
    cart: {
        totalAmount: null,
        deliveryCost: null,
        products: [],
    },
    initialProductType: null, // Initial product type choosen in Shop or POS
    customerData: null,
})

export const mutations = {
    updateField,
    setCart: (state, payload) => {
        state.cart = payload
    },
    setInitialProductType: (state, payload) => {
        state.initialProductType = payload
    },
    setStore: (state, payload) => {
        state.store = payload
    },
    setStoreReturnUrl: (state, payload) => {
        state.storeReturnUrl = payload
    },
    setCustomerData: (state, payload) => {
        state.customerData = payload
    },
}

export const getters = {
    getField,
}
