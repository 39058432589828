<template>
    <v-dialog
        :value="value"
        max-width="1000px"
        @input="$emit('input', false)"
    >
        <DialogCloseBtn @input="$emit('input', false)"></DialogCloseBtn>
        <div class="dialog-content">
            <div class="header-images-wrapper">
                <v-img
                    v-if="showHeaderImage"
                    class="header-comfino-logo"
                    :src="`/agreements/${isMobile ? 'logo-mobile.png' : 'logo-big.png'}`"
                    alt="comfino-logo"
                ></v-img>
            </div>
            <slot :close="closeDialog"></slot>
            <v-btn
                v-if="showCloseButton"
                class="bottom-close-button rounded-lg"
                :block="isMobile"
                outlined
                width="115px"
                color="#006FB2"
                @click="$emit('input', false)"
            >
                zamknij
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
import DialogCloseBtn from '~/components/partials/dialog/ close-button/DialogCloseBtn.vue'

export default {
    components: { DialogCloseBtn },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        showHeaderImage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isMobile () {
            return this.$vuetify.breakpoint.width < 550
        },
    },
    methods: {
        closeDialog () {
            this.$emit('input', false)
        },
    },
}
</script>

<style lang="scss">
.v-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    .dialog-content {
        overflow: auto;
        max-height: 100%;
        padding: 24px 16px;
        background: #fff;
    }
    img { // for image on top of TOS
        max-width: 100%;
    }
}

@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .v-dialog {
        .dialog-close {
            right: 16px;
        }
    }
}
</style>
