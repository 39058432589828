import Cookies from 'js-cookie'
import { COMFINO_SID_KEY } from '~/utils/consts'

export default function removeSession (token) {
    const URLObject = new URL(window.location)
    const cookieDomain = URLObject.host.replace('wniosek', '')

    Cookies.remove(COMFINO_SID_KEY, { path: '/', domain: cookieDomain })
    if (token) {
        Cookies.remove(`unlock_sms_sent_${token}`)
    }
}
